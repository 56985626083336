.shipping-pop
{
    display: block;
    position: absolute;
    min-width: 400px;
    border: 1px solid #cdcdcd;
    padding: 1em;
    border-radius: 5px;
    z-index: 999;
    margin-top: -35px;
    background: white;
    margin-left: 45px;
}
.shipping-header{
    text-align: right;
    margin-bottom: 1em;
}

.calc-icon
{
    width: 30px;
    height: 30px;
    object-fit: contain;
    cursor: pointer;
}
.pt-5p{
    padding-top: 5px !important;
}

.d-iflex
{
    display: inline-flex;
}
.w-75
{
    width: 75%;
}
.calc-input
{
    margin-top: 5px;
    margin-left: 10px;
}