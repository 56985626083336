/*
* Custom Fonts 
*/
@import url('https://fonts.googleapis.com/css2?family=Passion+One:wght@700&display=swap');


.si-01-h{
    display: flex;
    justify-content: space-between;
}



.si01-print-body
{
	margin-top: 1em;
	margin-bottom: 1em;
}

.si01-watermark {
    display: inline;
    position: absolute  !important;
    font-size: 3em;
    width: 100%;
    text-align: center;
    z-index: 1000;
    top:220px;
    right:20px;
    font-family: "Arial", Times, serif !important;
    color:lightgrey !important;
    transform: rotate(320deg); 
    -webkit-transform: rotate(320deg); 
    opacity:0.4 !important;
    font-size: 250px !important;
    font-weight: 600 !important;
}


.si01-ht{
    text-align: center;
    color: #373b46;
}

.psi01-address{
    margin-top: -5px;
    font-size: 14px;
    margin-bottom: 4px;
}



.si01-text-black {
    color: #000
}

.si01-top-header{
    margin-bottom: 10px;
}

.si01-company{
    font-family: 'Vollkorn', serif !important;
    text-transform: uppercase;
    font-size: 30px;
    font-weight: 600;
    padding-bottom: 0px;
    margin-top: 10px;
}

.si01-company-logo img{
    width: 60px;
    object-fit: cover;
}


.si01-h{
    display: flex;
    justify-content: space-between;
}

.si01-hl {
    /* width: 520px; */
}

.si-lbl{
    margin-bottom: 1px;
}

.si01-16b{
    font-size: 16px;
    font-weight: bold;
    color: #373b46;
}

.si01-16{
    font-size: 16px;
    color: #000;
}



.ps01-address{
    position: absolute;
    margin-left: 10px;
    font-weight: normal !important;
}

.ps01-bus{
    position: absolute;
    margin-left: 10px;
    font-weight: normal !important;
}

.ps01-date{
    position: absolute;
    margin-left: 10px;
    font-weight: normal !important;
}

.lbl-r-ln{
    margin-left: -15px;
}


.ps01-tin{
    position: absolute;
    margin-left: 10px;
    color: #000;
    font-weight: normal;
}


.ps01-terms{
    position: absolute;
    margin-left: 10px;
    font-weight: normal !important;
    color: #000;
}


.ps01-sc{
    position: absolute;
    margin-left: 125px;
    font-weight: normal !important;
} 


.si01-text-black {
    color: #000
}

.psi01-table{
    width: 100%;
    color: #000
}

.psi01-table th{
    color: #000;
    padding: 7px;
    text-align: center;
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
}


.psi01-table th:nth-child(2){
    border-left: 1px solid #000;
    border-right: 1px solid #000;
}

.psi01-table th:nth-child(3){
    border-right: 1px solid #000;
}
.psi01-table th:nth-child(4){
    border-right: 1px solid #000;
}

.psi01-table td{
    padding: 7px;
}


.psi01-table .tbl-row td{
    padding: 7px 3px 7px 3px !important;
    font-size: 10px;
    color: #000;
}


.ln-sign{
    display: inline-block;   
    position: relative;
    vertical-align: bottom;
    width: 335px;
    margin-right: -100%;
    border-top: 1px solid black;
}

.lbl-sign{
    color: #000;
    padding-top: 5px;
    font-size: 11px;
}

.si01-f{
    padding-top: 20px;
    color: #000;
    text-align: center;
    font-weight: bold;
}






/*
*   PRINT DISPLAY STYLES HERE
*   Note: Print styles are different in usually css
*/

@media print {
    .print-text{
        color: black !important;
        text-decoration: none !important;
    }

    .si01-ht{
        margin-top: 20px !important;
    }
    
    .ps01-soldto .print-text{
        position:absolute;
        width: 200px;
        margin-left: 80px !important;
        margin-top: -20px !important;
    }

    .ps01-date{
        position: absolute;
        right: -90px;
        margin-top: -20px;
        font-weight: normal !important;
    }

    .psi01-date{
        position: absolute;
        right: 300px !important;
    }
    


    .ps01-address{
        margin-left: 25px !important;
    }

    .ps01-bus{
        margin-left: 90px !important;
        margin-top: -20px !important;
    }

    .ps01-tin{
        margin-left: 30px !important;
    }

    .ps01-po{
        font-size: 16px !important;
        margin-left: 10px !important;
        font-weight: normal !important;
    }

    .ps01-terms{
        margin-left: 10px !important;
    }


    .ps01-sc {
        margin-left: 130px !important;
    }
    
    .ln-sign {
        width: 423px !important;
    }

    .psi01-table th:first-child{
        border-left: 1px solid white !important;
    }
    
    .psi01-table th:last-child{
        border-right:  1px solid white !important;
    }

    .psi01-table-2 tr td:first-child{
        width: 400px !important;
    }
    
    .psi01-table-2 tr td:last-child{
        width: 300px !important;
    }

    .psi01-table-2 .preparedby span:nth-child(2){
        position: absolute;
        left: 17rem;
    }

    .psi01-table-2 .bottom-left div span{
        display: block;
        font-size: 10px;
    }

    .psi01-table-2 .bottom-right .p-left span{
        font-size: 10px;
    }

    .psi01-table-2 .bottom-right .p-right{
        margin-right: 2rem;
    }
    
    .psi01-table-2 .bottom-right .p-right span{
        font-size: 10px;
    }

}
/* END OF PRINT DISPLAY STYLES */


.psi01-cash{
    font-family: 'Passion One', cursive !important;
    font-size: 25px;
    font-weight: bold;
    color: #000;
    text-decoration: underline;
}

.psi01-no{
    font-size: 18px;
    font-weight: bold;
    padding-left: 10px;
    color: #000;
    margin: 10px 0;
}

.psi01-date{
    position: absolute;
    margin-left: 500px;
}


.ps01-soldto{
    margin-left: 10px !important;
}

.ps01-po{
    font-size: 16px !important;
    color: #000;
    margin-left: 0px !important;
    font-weight: normal !important;
}


.border1{
    border: 1px solid #000 !important;
}

.psi01-table-2 tr td:first-child{
    border-left: 1px solid white !important;
    width: 300px;
}

.psi01-table-2 tr td:last-child{
    border-right: 1px solid white !important;
    width: 240px;
}

.psi01-table-2 .terms{
    font-size: 10px;
    text-align: justify;
    padding: 3px 5px;
}

.psi01-table-2 .return{
    font-size: 14px;
    text-align: center;
    padding: 4px;
}

.psi01-table-2 .amounts{
    padding: 4px;
}


.psi01-table-2 .amounts span{
    display: block;
    font-size: 14px;
}

.psi01-table-2 .amounts span:last-child{
    font-weight: bold;
    margin-top: 10px;
}

.psi01-table-2 tr:nth-child(2) td{
    border-right: 1px solid #000;
}

.psi01-table-2 .preparedby{
    height: 100px;
    font-size: 12px;
}

.psi01-table-2 .preparedby span{
    display: block;
    margin-top: 10px;
}

.psi01-table-2 .preparedby span:nth-child(2){
    margin-right: 70px;
}


.psi01-table-2 .preparedby-ln{
    margin-bottom: 25px;
}

.psi01-table-2 .preparedby-ln div:first-child{  
    width: 40% !important;
    border-top: 1px solid black !important;
}

.psi01-table-2 .preparedby-ln div:last-child{  
    width: 40% !important;
    border-top: 1px solid black !important;
    margin-right: 15px;
    color: #000;
}

.psi01-table-2 .preparedby-ln div>span{
    position: absolute;
    margin-top: -25px;
    color: #000;
}


.psi01-table-2 .customer-ac{
    text-align: center;
}

.psi01-table-2 .customer-ac span{
    display: block;
}

.psi01-table-2 .customer-ac span:nth-child(2), .psi01-table-2 .customer-ac span:last-child{
    font-size: 12px;
}   

.psi01-table-2  .customer-ac-ln{
    /* position: re; */
    margin-top: 25px;
    /* margin-bottom: -20px; */
}

.psi01-table-2  .customer-ac-ln div> span {
    display: block;
    text-align: center;
}

.psi01-table-2  .customer-ac-ln div:first-child span:nth-child(2) {
    width: 200px !important;
    border-top: 1px solid black !important;
    margin-left: 20px;
    text-align: center;
    color: #000;
}

.psi01-table-2  .customer-ac-ln div> span:last-child{
    font-size: 11px;
    margin-top: 2px;
    margin-left: 20px;
}

.psi01-table-2  .customer-ac-ln div:first-child > span:last-child{
    margin-left: 12px;
}


.psi01-table-2  .customer-ac-ln div:last-child span:nth-child(2) {
    width: 180px !important;
    border-top: 1px solid black !important;
    margin-left: 20px;
    text-align: center;
    color: #000;
}

.psi01-table-2  .customer-ac-ln div span:first-child {
    color: #000;
}

.psi01-table-2 tr{
    border-bottom: 1px solid white ;
    border-right: 1px solid white;
    margin-top: 4px;
}

.psi01-table-2 .bottom-left img{
    height: 20px;
    margin-top: 1px;
}

.psi01-table-2 .bottom-left div{
    margin-left: 2px;
}

.psi01-table-2 .bottom-left div span{
    display: block;
    font-size: 8px;
}


.psi01-table-2 .bottom-right .p-left span{
    display: block;
    font-size: 8px;
}

.psi01-table-2 .bottom-right .p-right{
    margin-top: 8px;
}

.psi01-table-2 .bottom-right .p-right span{
    display: block;
    font-size: 9px;
    text-align: center;
    font-weight: 600;
}