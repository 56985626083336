
.si-01-h{
    display: flex;
    justify-content: space-between;
}

.si01-no{
    padding-left: 10px;
    color:#FF4259;
}

.si01-print-body
{
	margin-top: 1em;
	margin-bottom: 1em;
}

.si01-watermark {
    display: inline;
    position: absolute  !important;
    font-size: 3em;
    width: 100%;
    text-align: center;
    z-index: 1000;
    top:220px;
    right:20px;
    font-family: "Arial", Times, serif !important;
    color:lightgrey !important;
    transform: rotate(320deg); 
    -webkit-transform: rotate(320deg); 
    opacity:0.4 !important;
    font-size: 250px !important;
    font-weight: 600 !important;
}


.si01-ht{
    text-align: center;
    color: #373b46;
}

.si01-address{
    margin-top: -5px;
    font-size: 16px;
}
.si01-tin{
    margin-top: 1px;
    font-size: 16px;
}

.si01-text-black {
    color: #000
}

.si01-company{
    font-family: 'Vollkorn', serif !important;
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 600;
    padding-bottom: 0px;
}


.si01-h{
    display: flex;
    justify-content: space-between;
}

.si01-hl {
    width: 520px;
}

.si-lbl{
    margin-bottom: 10px;
}

.si01-16b{
    font-size: 16px;
    font-weight: bold;
    color: #373b46;
}

.si01-16{
    font-size: 16px;
    color: #000;
}


.ln-soldto{
    display: inline-block;   
    position: relative;
    vertical-align: bottom;
    width: 435px;
    margin-right: -100%;
    border-top: 1px solid black;
}

.vl-soldto{
    position: absolute;
    margin-left: 55px;
    font-weight: normal !important;
}

.ln-tin{
    display: inline-block;   
    position: relative;
    vertical-align: bottom;
    width: 463px;
    margin-right: -100%;
    border-top: 1px solid black;
}

.vl-tin{
    position: absolute;
    margin-left: 23px;
    font-weight: normal !important;
}

.ln-address{
    display: inline-block;   
    position: relative;
    vertical-align: bottom;
    width: 428px;
    margin-right: -100%;
    border-top: 1px solid black;
}

.vl-address{
    position: absolute;
    margin-left: 60px;
    font-weight: normal !important;
}

.ln-bus{
    display: inline-block;   
    position: relative;
    vertical-align: bottom;
    width: 373px;
    margin-right: -100%;
    border-top: 1px solid black;
}

.vl-bus{
    position: absolute;
    margin-left: 115px;
    font-weight: normal !important;
}

.lbl-r-ln{
    margin-left: -15px;
}

.ln-date{
    display: inline-block;   
    position: relative;
    vertical-align: bottom;
    width: 203px;
    margin-right: -100%;
    border-top: 1px solid black;
}

.vl-date{
    position: absolute;
    margin-left: 30px;
    font-weight: normal !important;
}

.ln-terms{
    display: inline-block;   
    position: relative;
    vertical-align: bottom;
    width: 192px;
    margin-right: -100%;
    border-top: 1px solid black;
}

.vl-terms{
    position: absolute;
    margin-left: 40px;
    font-weight: normal !important;
}

.ln-osc{
    display: inline-block;   
    position: relative;
    vertical-align: bottom;
    width: 118px;
    margin-right: -100%;
    border-top: 1px solid black;
}

.vl-osc{
    position: absolute;
    margin-left: 111px;
    font-weight: normal !important;
}

.ln-sc{
    display: inline-block;   
    position: relative;
    vertical-align: bottom;
    width: 103px;
    margin-right: -100%;
    border-top: 1px solid black;
}

.vl-sc{
    position: absolute;
    margin-left: 125px;
    font-weight: normal !important;
} 


.si01-text-black {
    color: #000
}

.si01-table{
    border: 3px solid #000;
    width: 100%;
    color: #000
}

.si01-table th{
    border: 1px solid #000;
    color: #000;
    padding: 7px;
    text-align: center;
}

.si01-table td{
    border: 1px solid #000;
    padding: 7px;
}


.si01-table .tbl-row td{
    padding: 7px 3px 7px 3px !important;
    font-size: 10px;
    color: #000;
}


.ln-sign{
    display: inline-block;   
    position: relative;
    vertical-align: bottom;
    width: 335px;
    margin-right: -100%;
    border-top: 1px solid black;
}

.lbl-sign{
    color: #000;
    padding-top: 5px;
    font-size: 11px;
}

.si01-f{
    padding-top: 20px;
    color: #000;
    text-align: center;
    font-weight: bold;
}


@media print {
    .print-text{
        color: black !important;
        text-decoration: none !important;
    }

    .si01-ht{
        margin-top: 20px !important;
    }

    .ln-soldto{
        width: 543px !important;
    }
    
    .vl-soldto{
        margin-left: 64px !important;
    }

    .ln-tin{
        width: 570px !important;
    }

    .vl-tin{
        margin-left: 35px !important;
    }

    .ln-address{
        width: 538px !important;
    }

    .vl-address{
        margin-left: 65px !important;
    }

    .ln-bus{
        width: 480px !important;
    }

    .vl-bus{
        margin-left: 125px !important;
    }

    .ln-date{
        width: 259px !important;
    }

    .vl-date{
        margin-left: 30px !important;
    }

    .ln-terms{
        width: 245px !important;
    }
    .vl-terms{
        margin-left: 44px !important;
    }

    .ln-osc{
        width: 172px !important;
    }

    .vl-osc{
        margin-left: 115px !important;
    }

    .ln-sc{
        width: 156px !important;
    }

    .vl-sc {
        margin-left: 130px !important;
    }
    
    .ln-sign {
        width: 423px !important;
    }
}