.box-pop
{
    display: block;
    position: absolute;
    min-width: 350px;
    border: 1px solid #cdcdcd;
    padding: 1em;
    border-radius: 5px;
    z-index: 999;
    margin-top: 0px;
    background: white;
    margin-left: -17.5%;
}
.box-header
{
    text-align: right;
    margin-bottom: 1em;
}

.ml-19
{
    margin-left: -19% !important;
}