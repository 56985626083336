.st01-f-18b{
    font-size: 18px;
    font-weight: bold;
    color: #373b46;
}

.st01-text-black {
    color: #000
}

.st01-bold{
    color: #373b46;
    font-weight: bold;
}


.st01-border-r, .st01-border-l{
    border: 1 solid black;
}

.mw-195{
    max-width: 195px !important;
}

.st01-w-100{
    width: 100% !important;
}

.st01-h{
    display: flex;
    justify-content: space-between;
}

.st01-hl {
    width: 520px;
}

.st01-company{
    text-transform: uppercase;
}

.st01-h-border{
    border: 1px solid black;
    padding: 2px 6px;
    border-radius: 5px;
    margin-bottom: 5px;
}


.st01-t-first {
    border-collapse:separate;
    border: 1px solid black; 
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none !important;
    width: 100%;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
}

.st01-t-first th{
    border-top: none;
}

.st01-t-first td{
    padding: 7px;
    border-bottom:1px solid black;
    border-collapse: collapse;
}


.st01-t-first tr td:nth-child(1) {
    border-right: none;
}


.st01-t-first tr td:nth-child(2) {
    border-left: none;
}


/* second table*/
.st01-t-second {
    border-collapse:separate;
    border: 1px solid black;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: none !important;

}

.st01-t-second td{
    padding: 7px;
    border-bottom: 1px solid black;
    border-collapse: collapse;
}

.st01-t-second td:first-child{
    border-right: 1px solid black;
}

.st01-t-border-none-r{
    border-right: none !important;
}

.st01-t-border-none-b{
    border-bottom: none !important;
}

.st01-t-border-r{
    border-right: 1px solid black !important;
}


.st01-t-second td:nth-child(1), .st01-t-second td:nth-child(2){
    border-top: none !important;
}

.st01-item-row{
    /* font-size: 12px !important; */
    padding: 7px !important;
    text-align: center;
}

.st01-align-top {
    /* font-size: 10px !important; */
    padding-top: 7px !important;
    vertical-align: text-top !important;
}

.st01-inv-follow {
    font-size: 12px !important;
    font-weight: bold;
}

.st01-text{
    font-size: 12px;
}

.st01-print-body
{
	margin-top: 1em;
	margin-bottom: 1em;
}


.st01-footer-text{
    text-align: center;
    color: #373b46;
    font-size: 10px;
    font-weight: bold;
}


.st01-watermark {
    display: inline;
    position: absolute  !important;
    font-size: 3em;
    width: 100%;
    text-align: center;
    z-index: 1000;
    top:380px;
    right:20px;
    font-family: "Arial", Times, serif !important;
    color:lightgrey !important;
    transform: rotate(320deg); 
    -webkit-transform: rotate(320deg); 
    opacity:0.4 !important;
    font-size: 250px !important;
    font-weight: 600 !important;
}



@media print {
    *{
        color: black !important;
    }

    .st01-c-print{
        text-decoration: none !important;
    }
}