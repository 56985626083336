
.image--cover {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin: 20px;
  border: 1px solid #848eff;
  object-fit: cover;
  
}

.btn-pass{
	margin-left: 10px;
    padding: 2px 5px;
    padding-top: 2px;
    padding-right: 5px;
    padding-bottom: 2px;
    padding-left: 5px;
    border-radius: 4px;

}

.box-head{

background-color: #f1f1f1;	
text-align: center !important;
}
.box-subs{
    border: 2px solid #f1f1f1;

}


#uploadfile {
  display: none;
}

.profile-img-container {
    position: absolute;
    width:50%;
}

.profile-img-container img:hover {
    opacity: 0.5;
    z-index: 501;
}

.profile-img-container img:hover + i {
    display: block;
    z-index: 500;
}

.profile-img-container i {
    display: none;
    position: absolute;
    margin-left:43%;
    margin-top:40%;
}

.profile-img-container img {
    position:absolute;
}


.sales-box{
    border: 1px solid #8d909d;
}

.title-head{
  display: inline;
}

.payment-logo{
 width: 50px;
  height: 50px;
   
}

.subs-month{
display: block;
}

.icon-sub{
  font-size: 48px;
}
.wrap-box{
  width: 300px;
}