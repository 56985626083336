/* MOTOTRUCK TEMPLATE NEW */

.receipt-container {
    font-family: 'Times New Roman', Times, serif;
    color: #000;
}

.receipt-header {
    line-height: 1rem;
}

.receipt-header .receipt-mototruck {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -10px;
}

.receipt-header .company-name {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
        'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 28px;
    font-weight: bold;
    transform: scale(1, 0.7);
}

.receipt-header .company-details {
    font-size: 12px;
}

.receipt-float {
    display: flex;
    justify-content: space-between;
    transform: translateY(-15px);
}

.receipt-float .charge {
    text-decoration: underline;
    font-size: 18px;
}

.receipt-float .date {
    align-self: flex-end;
}

/* 
.table-header {
    border-bottom: 1px solid #000;
    border-top: solid #000;
} */

.table-header .product-description {
    width: 60%;
}

.table-header .text-center {
    padding: 0 !important;
}

.table-header .product-price div {
    font-size: 10px;
    color: black;
}

.receipt-table {
    padding-left: 0 !important;
}

.receipt-table .table {
    color: black;
}

.table-print tbody {
    border: none !important;
}

.receipt-terms-row {
    display: flex;
    justify-content: center;
    font-size: 10px;
    margin: 1px;
}

.receipt-terms-row .fs {
    font-size: 12px;
}

.receipt-terms-row .tab-footer {
    border-bottom: 1px solid #000;
    border-top: 1px solid #000;
    padding: 0.5rem 1rem 0.5rem 1rem;
}

.receipt-terms-row .left {
    border-right: 1px solid #000;
}

.receipt-acknowledgement {
    padding-right: 0 !important;
    margin-top: -0.1rem;
}

.receipt-acknowledgement .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.receipt-acknowledgement-row .tab-acknowledgement {
    padding: 0.5rem 2rem 0.5rem 2rem;
    border-bottom: 1px solid #000;
}

.receipt-acknowledgement-row .left {
    display: flex;
    justify-content: space-between;
    border-right: 1px solid #000;
}

.receipt-acknowledgement-row .right h5 {
    font-family: 'Times New Roman', Times, serif;
    color: black;
}

.receipt-acknowledgement-row .right p {
    font-size: 12px;
    line-height: 14px;
}

.tab-acknowledgement .customer-acknowledgement {
    line-height: 10px;
}

.tab-acknowledgement .supplier-acknowledgement {
    line-height: 10px;
}

.tab-acknowledgement .receipt-sig {
    margin: 1px;
    font-size: 9px;
}

.receipt-footer {
    font-size: 6px;
    letter-spacing: 0.5px;
}

.receipt-footer .mid {
    margin-left: auto;
}

.receipt-footer .quote {
    font-size: 8px;
}

.table tr.empty-row td {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.table.new-table th,
.table.new-table td {
    border: 1px solid black;
    padding: 0.5rem 0.75rem !important;
    line-height: 1.2;
}

.table.new-table .bottom-row td {
    font-size: 8px !important;
    padding: 0 !important;
    line-height: 1;
    color: black;
}

.bottom-row.total-amount td.title,
.bottom-row.total-amount td.amount-due {
    padding: 0px 6px !important;
    font-size: 12px !important;
}

table.table-with-border,
.table-with-border tr,
.table-with-border td,
.table-with-border th {
    border: 1px solid gainsboro;
    border-collapse: collapse;
}

/* MOTOTRUCK TEMPLATE OLD

.receipt-container {
    font-family: 'Times New Roman', Times, serif;
    color: #000;
}

.receipt-header {
    line-height: 1rem;
}

.receipt-header .receipt-mototruck {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: -10px;
}

.receipt-header .company-name {
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande',
        'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    font-size: 28px;
    font-weight: bold;
    transform: scale(1, 0.7);
}

.receipt-header .company-details {
    font-size: 12px;
}

.receipt-float {
    display: flex;
    justify-content: space-between;
    transform: translateY(-15px);
}

.receipt-float .charge {
    text-decoration: underline;
    font-size: 18px;
}

.receipt-float .date {
    align-self: flex-end;
}


.table-header {
    border-bottom: 1px solid #000;
    border-top: solid #000;
}

.table-header .header-title {
    border-right: 2px solid #000;
}

.table-header .product-unit {
    width: 55px;
}

.receipt-table {
    padding-left: 0 !important;
}

.receipt-table .table {
    color: black;
}

.table .receipt-thead th {
    border-bottom: 2px solid #000;
    border-top: 2px solid #000;
}

.table-print tbody {
    border: none !important;
}

.receipt-terms-row {
    display: flex;
    justify-content: center;
    font-size: 10px;
    margin: 1px;
}

.receipt-terms-row .fs {
    font-size: 12px;
}

.receipt-terms-row .tab-footer {
    border-bottom: 1px solid #000;
    border-top: 1px solid #000;
    padding: 0.5rem 1rem 0.5rem 1rem;
}

.receipt-terms-row .left {
    border-right: 1px solid #000;
}

.receipt-acknowledgement {
    padding-right: 0 !important;
    margin-top: -0.1rem;
}

.receipt-acknowledgement .row {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.receipt-acknowledgement-row .tab-acknowledgement {
    padding: 0.5rem 2rem 0.5rem 2rem;
    border-bottom: 1px solid #000;
}

.receipt-acknowledgement-row .left {
    display: flex;
    justify-content: space-between;
    border-right: 1px solid #000;
}

.receipt-acknowledgement-row .right h5 {
    font-family: 'Times New Roman', Times, serif;
    color: black;
}

.receipt-acknowledgement-row .right p {
    font-size: 12px;
    line-height: 14px;
}

.tab-acknowledgement .customer-acknowledgement {
    line-height: 10px;
}

.tab-acknowledgement .supplier-acknowledgement {
    line-height: 10px;
}

.tab-acknowledgement .receipt-sig {
    margin: 1px;
    font-size: 9px;
}

.receipt-footer {
    font-size: 6px;
    letter-spacing: 0.5px;
}

.receipt-footer .mid {
    margin-left: auto;
}

.receipt-footer .quote {
    font-size: 8px;
} */
